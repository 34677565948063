import { ServiceCard } from "./serviceCard"
export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Our Services</h2>
          <p>We serve residential, commercial, or semi-industrial clients with top garage door installation and repair services. </p>
        </div>
        <div className='row'>
          <ServiceCard></ServiceCard>
        </div>
      </div>
    </div>
  )
}
