import { useMemo } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

export const Home = (props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCS03Tn60gYocJykq2oXgWltwiJKdQy1gc',
  });
  console.log(isLoaded)
  if (!isLoaded) return <div>Loading...</div>;
  return <Map />;
}

function Map() {
  const center = useMemo(() => ({ lat: 41.8555869457664,  lng: -88.12680154444855 }), []);
  return (
    <div className="map-container" >
    </div>
  );
}