import { useState } from 'react'
import axios from "axios";

const initialState = {
  name: '',
  email: '',
  phone: '',
  service: ''
}
export const Form = (props) => {
  const [{ name, email, phone, service}, setState] = useState(initialState)
  const [success, setSucces] = useState(false)
  console.log(name)
  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })
  const params = {
    name, email, phone, service
  };
  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, phone, service)
    axios.get('https://hooks.zapier.com/hooks/catch/13827609/b729ib8/', { params })
      .then((response) => {
          console.log(e.target)
          clearState()
          setSucces(true)
      });
  }
  return (
    <div>
      <div id='form'>
        <div className='container'>
          <div className='col-md-12'>
              <div>
                <div className='form-logo' style={{display: 'flex', alignItems: 'center'}}>
                  <img
                  width="45"
                  height="45"
                  src="img/logos/logo.png"
                  className="attachment-full size-full"
                  alt=""
                  loading="lazy"
                  />
                  <h2>Schedule Garage Door Services in Chicago</h2>
                </div>
                <p>Please fill in our quick form. We will contact you to discuss your service details and schedule an appointment.</p>
              </div>
              
              {success ? <h2 id='success'>Thanks for contacting us! We will be in touch with you shortly.</h2> :
              <form name='sentMessage' validate onSubmit={handleSubmit} style={{marginBottom: 0}}>
                <div className='row form-container'>
                  <div className='col-md-20 col-xs-12'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-20 col-xs-12'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='phone'
                        name='phone'
                        className='form-control'
                        placeholder='Phone'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-20 col-xs-12'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-20 col-xs-12'>
                    <div className='form-group'>
                      <select 
                      name='service'
                      id='service'
                      className='form-control select-wrapper'
                      placeholder='Type of Service'
                      required
                      onChange={handleChange}
                      >
                        <option >Type Of Service</option>
                        <option value="newDoorInstalllation">New Door Installation</option>
                        <option value="garageDoorRepair">Garage Door Repair</option>
                        <option value="openerReplacement">Opener Replacement</option>
                        <option value="springReplacement">Spring Replacement</option>
                        <option value="garageDoorInstall">Off Track Doors Fix</option>
                        <option value="garageDoorInstall">Automatic Garage Door</option>
                      </select>
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-button col-xs-12'>
                    <button  type='submit' className="theme-btn-one btn-shape " >
                      Send
                    </button>
                  </div>
                </div>
              </form>
              }
          </div>
        </div>
      </div>
    </div>
  )
}
