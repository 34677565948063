export const ServiceCard = () => {
  return (
    <div className="auto-container">
      <div className="row clearfix">
        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 service-block">
          <div className="service-block-one">
            <div className="inner-box">
              <figure className="image-box">
                  <img
                    width="100%"
                    height="280"
                    src="https://dormatic.smartdemowp.com/wp-content/uploads/2021/01/service-1.jpg"
                    className="attachment-full size-full"
                    alt=""
                    loading="lazy"
                  />{" "}
              </figure>
              <div className="lower-content">
                <div className="text">
                  <h3>
                    Garage Door Installation
                  </h3>
                  <p>
                    Working on a new build? Looking to renovate your garage completely? Neil’s has a wide selection of garage doors to meet your aesthetic vision, durability expectations, and convenience needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-sm-12 service-block">
          <div className="service-block-one">
            <div className="inner-box">
              <figure className="image-box">
                  <img
                    width="100%"
                    height="280"
                    src="https://dormatic.smartdemowp.com/wp-content/uploads/2021/01/service-2.jpg"
                    className="attachment-full size-full"
                    alt=""
                    loading="lazy"
                  />{" "}
              </figure>
              <div className="lower-content">
                <div className="text">
                  <h3>
                    Garage Door Replacement
                  </h3>
                  <p>
                  We can remove and dispose of your old garage door and install your ideal garage door all in the same day. Take advantage of our 5-star garage door replacement service in Chicago.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-sm-12 service-block">
          <div className="service-block-one">
            <div className="inner-box">
              <figure className="image-box">
                  <img
                    width="100%"
                    height="280"
                    src="https://dormatic.smartdemowp.com/wp-content/uploads/service-3.jpg"
                    className="attachment-full size-full"
                    alt=""
                    loading="lazy"
                  />{" "}
              </figure>
              <div className="lower-content">
                <div className="text">
                  <h3>
                      Garage Door Repair
                  </h3>
                  <p>
                    You may experience small or inconvenient garage door issues requiring top-quality repair services. We can repair or replace broken panels, weather strips, sensors, wires, and more.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-sm-12 service-block">
          <div className="service-block-one">
            <div className="inner-box">
              <figure className="image-box">
                  <img
                    width="100%"
                    height="280"
                    src="https://dormatic.smartdemowp.com/wp-content/uploads/service-6.jpg"
                    className="attachment-full size-full"
                    alt=""
                    loading="lazy"
                  />{" "}
              </figure>
              <div className="lower-content">
                <div className="text">
                  <h3>
                  Spring replacements
                  </h3>
                  <p>
                  The springs on your garage door are essential to smooth, safe, and quiet operation. We offer durable springs and quick replacement, so you can enjoy using your garage door again.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-sm-12 service-block">
          <div className="service-block-one">
            <div className="inner-box">
              <figure className="image-box">
                  <img
                    width="100%"
                    height="280"
                    src="https://dormatic.smartdemowp.com/wp-content/uploads/service-7.jpg"
                    className="attachment-full size-full"
                    alt=""
                    loading="lazy"
                  />{" "}
              </figure>
              <div className="lower-content">
                <div className="text">
                  <h3>
                    Off-Track Garage Door Repairs
                  </h3>
                  <p>
                    Garage doors can come off their tracks for a number of reasons. At Neil’s Garage Doors, our team can do an evaluation and put your door back on its tracks in a timely manner. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-sm-12 service-block">
          <div className="service-block-one">
            <div className="inner-box">
              <figure className="image-box">
                  <img
                    width="100%"
                    height="280"
                    src="https://dormatic.smartdemowp.com/wp-content/uploads/service-8.jpg"
                    className="attachment-full size-full"
                    alt=""
                    loading="lazy"
                  />{" "}
              </figure>
              <div className="lower-content">
                <div className="text">
                  <h3>
                      Automatic Garage Doors
                  </h3>
                  <p>
                  Need a new automatic garage door opener? We have a wide selection of garage door openers, so you can choose the one that works best for you and your needs. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
