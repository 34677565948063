export const ServiceArea = (props) => {
  return (
    <div className="auto-container service-area">
      <div className="info-inner">
        <div className="row clearfix">
          <div className="col-lg-7 col-md-12 col-sm-12 content-column">
            <div className="content_block_3">
              <div className="content-box">
                <figure className="icon-layer">
                  <img
                    width="241"
                    height="300"
                    src="https://dormatic.smartdemowp.com/wp-content/uploads/icon-9.png"
                    className="attachment-full size-full"
                    alt=""
                    loading="lazy"
                  />{" "}
                </figure>
                <div className="sec-title">
                  <h2>Areas We Cover</h2>
                </div>
                <div className="text">
                  <ul className="location-list clearfix">
                    <li>Deerfield</li>
                    <li>Northbrook</li>
                    <li>Highwood</li>
                    <li>Highland Park</li>
                    <li>Glencoe</li>
                    <li>Winnetka</li>
                    <li>Glenview</li>
                    <li>Wheeling</li>
                    <li>Buffalo Grove</li>
                    <li>Kideer</li>
                    <li>Deer Park</li>
                    <li>Barrington</li>
                    <li>Iverness</li>
                    <li>Palatine</li>
                    <li>Arlington Heights</li>
                    <li>Mt Prospect</li>
                    <li>Des Plaines</li>
                    <li>Park Ridhe</li>
                    <li>Skokie</li>
                    <li>Niles</li>
                    <li>Norridge</li>
                    <li>Chicago</li>
                    <li>Elgin</li>
                    <li>Streamwood</li>
                    <li>Hoffman Estate</li>
                    <li>Schaumburg</li>
                    <li>Elk Grove Village</li>
                    <li>Wood Dale</li>
                    <li>Wayne</li>
                    <li>Carol Stream</li>
                    <li>Glendale Heights</li>
                    <li>Villa Park</li>
                    <li>St. Charles</li>
                    <li>Lakewood</li>
                    <li>Aurora</li>{" "}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12 content-column service-area-image" >
            <div className="content_block_4">
              <div
                className="content-box"
                style={{backgroundImage: "url(https://dormatic.smartdemowp.com/wp-content/uploads/contact-1.jpg)"}}
              >
                  <div className="call-now" style={{width: '100%'}}>
                    <div className="inner">
                      <i className="fa-solid fa-mobile-screen-button"></i>
                      <div style={{paddingLeft: '10px'}}>
                        <span> Book an Appointment Today!</span>
                        <h3><a href="tel:3126347059">(+1) 312 634-7059 </a></h3>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
