export const Footer = (props) => {
  return (
    <div>
      
      <footer className="site-footer">
        <div className="container">
          <div className="row" style={{ marginBottom: "45px" }}>
            <div className="col-sm-12 col-md-4" style={{display:'flex', alignItems:'center'}}>
              <div className="footer-widget logo-widget">
                <figure className="footer-logo">
                  <a href="/">
                    <img
                      width="200"
                      height="50"
                      src="img/logos/footerLogo.png"
                      className="attachment-full size-full"
                      alt=""
                      loading="lazy"
                    />{" "}
                  </a>
                </figure>
                <div className="text">
                  <p>Our ultimate mission is to make your 
                    life easier by providing exceptional 
                    garage door solutions to homeowners
                    and business owners across Chicago. 
                    We strive to leave our clients and 
                    our community better than we 
                    found it!
                  </p>
                </div>
                <div className="row">
                  <div className="social" id="social-footer">
                    <ul>
                      <li>
                        <a href={props.data ? props.data.facebook : "/"}>
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href={props.data ? props.data.instagram : "/"}>
                          <i className="fa fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href={props.data ? props.data.yelp : "/"}>
                          <i className="fa fa-yelp"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xs-6 col-md-4">
              <div className="widget-title">
                <h3>Sections</h3>
              </div>

              <div className="widget-content">
                <ul className="footer-links">
                  <li>
                    <a href="#about" className="page-scroll">
                      About
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a href="#services" className="page-scroll">
                      Services
                    </a>{" "}
                  </li>
                  <li>
                    <a href="#portfolio" className="page-scroll">
                      Gallery
                    </a>
                  </li>
                  <li>
                    <a href="#testimonials" className="page-scroll">
                      Testimonials{" "}
                    </a>{" "}
                  </li>
                  <li>
                    <a href="#team" className="page-scroll">
                      Team
                    </a>
                  </li>
                  <li>
                    <a href="#contact" className="page-scroll">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xs-6 col-md-4">
              <div className="widget-title">
                <h3>Get In Touch</h3>
              </div>
              <div className="widget-content">
                <ul className="info clearfix">
                  <li>2152 West Monroe St, Chicago IL, 60612</li>
                  <li>
                    <a href="tel:+13126347059">(+1) 312 634-7059</a>
                  </li>
                  <li>
                    <span>Working Hours</span>
                  </li>
                  <li>Mon – Sun : 8am to 8pm</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id="footer">
          <div className="footer-container text-center">
            <p>Neil's - Garage Door Repairs (c) 2022</p>
          </div>
        </div>
      </footer>
    </div>
  );
};
