export const Testimonials = (props) => {
  return (
    <div id='testimonials'>
      <div className='container'>
        <div className='section-title text-center'>
          <h2>What Our Clients Have To Say</h2>
        </div>
        <div className='row'>
        <div class="elfsight-app-fd060d76-9b44-4f44-9297-9a0489a90db8"></div>
        </div>
      </div>
    </div>
  )
}
