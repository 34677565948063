import React from "react";
import Slider from "react-slick";

function BrandsSlider() {
  var brands = ['angi', 'chamberlain','genie', 'thumbstack', 'homeAdvisor','google', 'liftmaster' ,'yelp',]
    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 0,
        autoplay: true,
        responsive: [
          {
            breakpoint: 983,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 820,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              rows: 2,
              slidesPerRow: 1
            }
          }
        ]
      };
    
    return (
      <div id='brandsSlider' className='brandsSlider text-center'>
        <div className="other-text">
          <h3>Trusted By Chicago’s Residential & Commercial Customers</h3>
        </div>
        <Slider {...settings}>
          {brands.map(brand => <img src={`img/brands/${brand}.png`} alt={`${brand}-logo`}/>)}
        </Slider>
      </div>
    );
}

export default BrandsSlider;