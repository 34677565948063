export const Header = (props) => {
  return (
    <>
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <div className='container'>
              <div className='col-md-8 intro-text'>
                <h5><i className="fa fa-warehouse"></i>  Welcome to Neil’s Garage</h5>
                <h1>
                  {props.data ? props.data.title : 'Loading'}
                </h1>
                <p>{props.data ? props.data.paragraph : 'Loading'}</p>
                <div className="btn-box clearfix">
                  <div className="call-now">
                    <div className="inner">
                      <i className="fa-solid fa-mobile-screen-button"></i>
                      <div style={{paddingLeft: '10px'}}>
                        <span> Book an Appointment Today!</span>
                        <h3><a href="tel:3126347059">(+1) 312 634-7059 </a></h3>
                      </div>
                    </div>
                  </div>
                  <a href="#about" target="_blank" rel="nofollow" className="theme-btn-one"><span className="btn-shape"></span>learn more</a>
                  
                </div>
              </div>
          </div>
        </div>
      </div>
    </header>
    </>
  )
}
